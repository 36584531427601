import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Control, DomEvent, DomUtil, Map } from 'leaflet';

import { Indicator } from '../../models/Indicator';
import { ConnectionCostParameters, NewRoof } from '../../models/Solar';

import { AuthService } from '../AuthService';
import { LocalStorageService } from '../local-storage.service';
import { MapService } from '../map.service';
import { ModuleService } from '../module.service';
import { RestService } from '../RestService';
import { TerService } from '../TerService';
import { UsefulService } from '../UsefulService';

@Injectable({
    providedIn: 'root',
})
export class SolarService {
    public lomiaCtaMobileControl: Control;

    public selectedElementsObs = new BehaviorSubject<any[]>([]);
    selectedElementsObs$: Observable<any[]> = this.selectedElementsObs.asObservable();

    public mainStageObs = new BehaviorSubject<string>(null);
    mainStageObs$: Observable<string> = this.mainStageObs.asObservable();

    public pvStageObs = new BehaviorSubject<string>(null);
    pvStageObs$: Observable<string> = this.pvStageObs.asObservable();

    public newElementObs = new BehaviorSubject<any>(null);
    newElementObs$: Observable<any> = this.newElementObs.asObservable();

    public address: GeoJSON.Feature;

    public customInfo: any;

    public selectionInfo: {
        showLoader: boolean;
        myRoof: string;
        totalModuleArea: string;
        totalGrossRoofArea: string;
        direction: string;
        tilt: string;
    };
    public selectedElements: any[] = [];
    public indicatorPlot: Indicator;
    public technology: string;
    public moduleInfo: any;
    public totalGrossRoofArea: number;
    public isZoneConstraint: boolean;
    public valorization: string = 'Totality';

    public connectionCostInfo: {
        isEnedisAvailable: boolean;
        contactRegie: string;
        aecEstimation: any;
    };

    public results: any[];

    private titleByStage = {
        newRoofStage: { title: 'Simulation de projet solaire', subTitle: null },
        initStage: { title: 'Simulation de projet solaire', subTitle: null },
        parametersStage: { title: 'Simulation de projet solaire', subTitle: null },
        technologyStage: { title: 'Choix de la technologie', subTitle: null },
        thermalStage: { title: 'Simulation de projet : thermique', subTitle: null },
        pvStage: { title: 'Simulation de projet : photovoltaïque', subTitle: null },
        pvValorizationStage: { title: 'Simulation de projet : photovoltaïque', subTitle: null },
        pvTotalityStage: {
            title: 'Simulation de projet : photovoltaïque',
            subTitle: 'Vente totale',
        },
        pvSurplusStage: {
            title: 'Simulation de projet : photovoltaïque',
            subTitle: 'Autoconsommation avec revente du surplus',
        },
        resultsStage: { title: 'Mes simulations de projet', subTitle: null },
    };

    // private csvSchemaHeader = ['Champ', 'Valeur', 'Unité', 'Commentaire'];
    // private csvInitSchemaTmp = [
    //     ['Surface de toiture totale', 'totalGrossRoofArea', 'm²', ''],
    //     ['', '', '', ''],
    // ];

    // private csvBodySchema: any;

    constructor(
        @Inject(AuthService) private authService: AuthService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(MapService) private mapService: MapService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(RestService) private restService: RestService,
        @Inject(TerService) private terService: TerService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    clear() {
        this._clearObservables();
    }

    private _clearObservables() {
        this.selectedElementsObs.next([]);
        this.newElementObs.next(null);
        this.mainStageObs.next(null);
        this.pvStageObs.next(null);
    }

    updateMainStage(stage: string) {
        const isInitStage = [null, 'initStage', 'newRoofStage'].includes(stage);
        const isAnyResultDefined = this.results.length > 0;
        this.indicatorPlot.openPopup = isInitStage && !isAnyResultDefined;
        this.mainStageObs.next(stage);
    }

    updatePvStage(stage: string) {
        this.pvStageObs.next(stage);
    }

    async initSelectedElements() {
        this.removeAllElements();
        this.moduleService.openModule('solar');
    }

    initSelectionInfo() {
        this.selectionInfo = {
            showLoader: true,
            myRoof: '',
            totalModuleArea: '',
            totalGrossRoofArea: '',
            direction: '',
            tilt: '',
        };
    }

    initResults() {
        this.results = [];
    }

    addResult(result: any) {
        this.results.push(result);
    }

    removeResult(resultToRemove: any) {
        const index = this.results.indexOf(resultToRemove);
        this.results.splice(index, 1);
    }

    deselectAllElements() {
        this.selectedElements.forEach((element) => this.indicatorPlot.deselectElement(element.id));
    }

    removeElement(elementId: number | string) {
        this.indicatorPlot.deselectElement(elementId);
        this.selectedElements = this.selectedElements.filter(
            (element: any) => element.id != elementId,
        );
        this.checkZoneConstraint();
        this.selectedElementsObs.next(this.selectedElements);
    }

    removeAllElements() {
        this.deselectAllElements();
        this.selectedElements = [];
        this.checkZoneConstraint();
        this.selectedElementsObs.next(this.selectedElements);
    }

    keepOnlyFirstElement() {
        this.selectedElements
            .slice(1)
            .forEach((element) => this.indicatorPlot.deselectElement(element.id));
        this.selectedElements = this.selectedElements.slice(0, 1);
        this.selectedElementsObs.next(this.selectedElements);
    }

    async addSelectedElement(feature: GeoJSON.Feature) {
        this.selectionInfo.showLoader = true;
        const selectedElement = await this._getElementInfo(feature.properties);
        selectedElement.isNew = false;
        selectedElement.center = feature.properties.center;
        selectedElement.addressLabel = this.formatAddressLabel(feature);
        selectedElement.parcelLabel = this.formalParcelLabel(feature);
        this.setInfoByElement(selectedElement);

        this.addElement(selectedElement);
    }

    addElement(element: any) {
        this.selectedElements.push(element);
        this.checkZoneConstraint();
        this.selectedElementsObs.next(this.selectedElements);
    }

    private async _getElementInfo(element: any) {
        try {
            const parameters = {
                year: this.terService.geoYear,
                scaleTypeId: this.terService.territoryScale.typeId,
                territoryIds: JSON.stringify(this.terService.territories.map((t) => t.id)),
                indicatorId: element.id_indicateur,
                elementId: element.id,
            };

            const selectedElement = this.restService.getSolarElementInfo(parameters);
            return selectedElement;
        } catch (error) {
            console.error('Error _getElementInfo', error);
            throw error;
        }
    }

    async addNewElement(newElement: any, latlng: L.LatLng) {
        this.selectedElements.push(newElement);
        await this.checkConstraintZone(latlng);
        this.newElementObs.next(newElement);
    }

    removeNewElement() {
        this.newElementObs.next(null);
    }

    setNewElementParameters(newEement: NewRoof, parameters: Partial<NewRoof>) {
        const index = this.selectedElements.indexOf(newEement);
        this.selectedElements[index] = { ...newEement, ...parameters };

        this.connectionCostInfo = {
            isEnedisAvailable: false,
            contactRegie: '',
            aecEstimation: null,
        };
    }

    getElementInfo(parameters: any) {
        const params = {
            year: this.terService.geoYear,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: JSON.stringify(this.terService.territories.map((t) => t.id)),
        };
        Object.assign(parameters, params);
        return this.restService.getSolarElementInfo(parameters);
    }

    getSolarCustomTerritoryInfo() {
        const parameters = {
            year: this.terService.geoYear,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: JSON.stringify(this.terService.territories.map((t) => t.id)),
        };
        return this.restService.getSolarCustomTerritoryInfo(parameters);
    }

    saveSolarLogIn(label: string, latitude: number, longitude: number) {
        const user = this.localStorageService.get('user');
        const data = {
            userId: user.id,
            label: label,
            latitude: latitude,
            longitude: longitude,
        };
        this.restService.saveSolarLogIn(data);
    }

    isAlreadySelected(element: any) {
        return this.selectedElements.some(
            (selectedElement: any) => selectedElement.id == element.id,
        );
    }

    isDifferentTerritory() {
        let isSameYear = false;
        let isSameTypeTer = false;
        let isSameIdTer = false;

        if (this.hasOwnProperty('customInfo')) {
            isSameYear = this.customInfo.year == this.terService.geoYear;
            isSameTypeTer = this.customInfo.territoryType == this.terService.territoryScale.typeId;
            isSameIdTer =
                JSON.stringify(this.customInfo.territoryIds.sort()) ==
                JSON.stringify(this.terService.territories.map((t) => t.id).sort());
        }

        return [isSameYear, isSameTypeTer, isSameIdTer].includes(false);
    }

    setInfoByElement(element: any) {
        this.connectionCostInfo = element.connectionCostInfo;
        this._setUsableRoofAreaByElement(element);
        this._setRoofWindowAreaByElement(element);
        this._setRoofChimneyAreaByElement(element);
        this._setDirectionDetailByElement(element);
    }

    private _setUsableRoofAreaByElement(element: any = undefined) {
        if (element) {
            element.usableRoofArea = element.surface_disponible;
        } else {
            this.selectedElements.forEach(
                (element: any) => (element.usableRoofArea = element.surface_disponible),
            );
        }
    }

    private _setRoofWindowAreaByElement(element: any = undefined, value = 0) {
        if (element) {
            element.windowArea = value;
        } else {
            this.selectedElements.forEach((element: any) => {
                element.windowArea = value;
            });
        }
    }

    private _setRoofChimneyAreaByElement(element: any = undefined, value = 0) {
        if (element) {
            element.chimney = value;
        } else {
            this.selectedElements.forEach((element: any) => {
                element.chimney = value;
            });
        }
    }

    private _setDirectionDetailByElement(element: any = undefined) {
        if (element) {
            element.directionDetail = element.orientation_s;
        } else {
            this.selectedElements.forEach(
                (element: any) => (element.directionDetail = element.orientation_s),
            );
        }
    }

    setAddress(feature: GeoJSON.Feature) {
        this.address = feature;
        this.localStorageService.set('address', feature);
    }

    addMarker(geometry: GeoJSON.Point) {
        const lat = geometry.coordinates[1];
        const lng = geometry.coordinates[0];
        this.mapService.addMarkerAddress(lat, lng);
        this.mapService.centerOn(lat, lng, 18, false);
    }

    getAddress() {
        return this.address;
    }

    formatAddressLabel(feature: GeoJSON.Feature) {
        const address = feature.properties.adresse;
        const city = feature.properties.city;

        let adressLabel = city;
        if (address && !['Mutiple', 'Multiple'].includes(address)) {
            adressLabel = `${address}, ${adressLabel}`;
        }

        return adressLabel;
    }

    formalParcelLabel(feature: GeoJSON.Feature) {
        const parcel = feature.properties.parcel;
        let parcelLabel = '';
        if (parcel) {
            parcelLabel = `N° de parcelle : ${parcel}`;
        }
        return parcelLabel;
    }

    getFirstGreater(obj: { [ppeak: string]: number }, value: number) {
        const keysSorted = Object.keys(obj)
            .map((k) => parseFloat(k))
            .sort((a, b) => a - b);

        const firstGreater = keysSorted.find((k) => k >= value);
        return firstGreater;
    }

    getLastSmaller(obj: { [ppeak: string]: number }, value: number) {
        const keysSorted = Object.keys(obj)
            .map((k) => parseFloat(k))
            .sort((a, b) => b - a);

        const firstSmaller = keysSorted.find((k) => k < value);

        // between 0 and first value, no interpolation needed so we used first value
        return firstSmaller || keysSorted[0];
    }

    getLinearInterpolatedValue(obj: { [ppeak: string]: number }, value: number) {
        const x1 = this.getLastSmaller(obj, value);
        const x2 = this.getFirstGreater(obj, value);

        if (x1 === undefined || x2 === undefined) {
            throw new Error('Cannot interpolate, values not found.');
        }

        const y1 = obj[x1] || 0;
        const y2 = obj[x2];
        const y = y1 + ((value - x1) * (y2 - y1)) / (x2 - x1);

        return y;
    }

    getTitleByStage(stage: string) {
        return this.titleByStage[stage].title;
    }

    getSubTitleByStage(stage: string) {
        return this.titleByStage[stage].subTitle;
    }

    setTotalGrossRoofArea() {
        this.totalGrossRoofArea = this.selectedElements.reduce(
            (sum, element) => sum + element.surface_disponible,
            0,
        );
    }

    checkZoneConstraint() {
        this.isZoneConstraint = this.selectedElements.some(
            (element: any) => element.avap_zppaup >= 1,
        );
    }

    async getConnectionCost(valorization: string, subscribedPower: number, totalPowerPeak: number) {
        const data: ConnectionCostParameters = {
            valorization: valorization,
            subscribedPower: subscribedPower,
            injectedPower: totalPowerPeak,
            elements: this.selectedElements,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: this.terService.territories.map((t) => t.id),
            year: this.indicatorPlot.crrsdc_ter_year_geo,
        };

        const connectionCost = await this.restService.getConnectionCost(data);
        return connectionCost;
    }

    async getSolarPdfFile(result?: any) {
        const data = {
            year: this.terService.geoYear,
            scaleTypeId: this.terService.territoryScale.typeId,
            territoryIds: this.terService.territories.map((t) => t.id),
            isZoneConstraint: this.isZoneConstraint,
            centers: this.selectedElements.map((element) => element.center),
            selectionInfo: this.selectionInfo,
            simulations: result ? [result] : this.results,
            buildingIds: this.selectedElements.map((element) => element.id_cons),
            addresses: this.selectedElements.map((element) => ({
                address: element.addressLabel,
                parcel: element.parcelLabel,
            })),
        };

        const buffer = await this.restService.getSolarPdfFile(data);
        return buffer;
    }

    initCtaMobileControl() {
        const LomiaCtaMobileControl = Control.extend({
            onAdd(map: Map) {
                const element = DomUtil.get('lomia-cta-mobile-control');
                DomEvent.disableClickPropagation(element);
                return element;
            },
            onRemove(map: Map) {},
        });
        this.lomiaCtaMobileControl = new LomiaCtaMobileControl({
            position: 'bottomleft',
        });
    }

    addCtaMobileControlToMap() {
        this.lomiaCtaMobileControl.addTo(this.mapService.map);
    }

    removeCtaMobileControlFromMap() {
        this.mapService.map.removeControl(this.lomiaCtaMobileControl);
    }

    convertOrientation(orientation: string): number {
        let azimuth: number;
        switch (orientation) {
            case 'south':
                azimuth = 0;
                break;
            case 'west':
                azimuth = 90;
                break;
            case 'north':
                azimuth = 180;
                break;
            case 'est':
                azimuth = -90;
                break;
            case 'north-west':
                azimuth = 135;
                break;
            case 'north-est':
                azimuth = -135;
                break;
            case 'south-est':
                azimuth = -45;
                break;
            case 'south-west':
                azimuth = 45;
                break;
        }
        return azimuth;
    }

    checkIfSelectedElementsIsNew() {
        if (this.selectedElements.length > 1) {
            return false;
        }

        const selectedElement = this.selectedElements[0];
        const isNew = selectedElement.isNew;
        return isNew;
    }

    setTotalModuleAreaToSelectionInfo(totalModuleArea: number) {
        const stringifiedTotalModuleArea = this.usefulService.stringifyNumber(
            this.usefulService.round(totalModuleArea),
        );
        this.selectionInfo.totalModuleArea = stringifiedTotalModuleArea;
    }

    async checkConstraintZone(latlng: L.LatLng): Promise<void> {
        const parameters = {
            latitude: latlng.lat,
            longitude: latlng.lng,
        };
        this.isZoneConstraint = await this.restService.checkConstraintZone(parameters);
    }

    // createCsvData(data: any) {
    //     const csv = [];

    //     this.csvBodySchema.forEach((item: string[]) => {
    //         // item[1] - value
    //         if (item[1]) {
    //             const values = data[item[1]];
    //             item[1] = values.toString();
    //         }

    //         // item[3] - commentary
    //         if (!['', '-'].includes(item[3])) {
    //             const paths = item[3].split('.');
    //             const comment = paths.reduce(
    //                 (accumulator: any, key: string) => accumulator[key],
    //                 data,
    //             );
    //             item[3] = ['<p>', '</p>', '<ul>', '</ul>', '<li>', '</li>'].reduce(
    //                 (accumulator: any, tag: string) => accumulator.replaceAll(tag, ''),
    //                 comment,
    //             );
    //         }
    //     });
    //     csv.push(...this.csvBodySchema);

    //     return csv;
    // }

    // createCsvHeader() {
    //     const header = JSON.parse(JSON.stringify(this.csvSchemaHeader));
    //     // header.splice(1, 1, ...this.selectedElements.map((element) => element.id_parc_bati));

    //     return header;
    // }

    // initCsvSchema(addTopInfo: boolean = true) {
    //     if (addTopInfo) {
    //         this.csvBodySchema = JSON.parse(JSON.stringify(this.csvInitSchemaTmp));
    //     } else {
    //         this.csvBodySchema = [];
    //     }
    // }

    // setCsvSchema(bodySchema: string[][]) {
    //     this.csvBodySchema.push(...bodySchema);
    // }
}
