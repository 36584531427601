import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';

import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { UsefulService } from 'src/app/services/UsefulService';

@Component({
    selector: 'cadastreSolairePvSurplusProductionPlot',
    templateUrl: './cadastre-solaire-pv-surplus-production-plot.template.html',
    styleUrls: ['./cadastre-solaire-pv-surplus-production-plot.component.scss'],
})
export class CadastreSolairePvSurplusProductionPlotComponent implements OnInit {
    public labelCollapseProduction: string =
        'Afficher les détails d’autoconsommation, achat et vente d’électricité';
    public activeCollapseProduction: boolean = false;
    public activeTabId: number = 1;

    public showLoader: boolean = false;
    public isDownloading: boolean = false;
    public showMobileUi = false;

    constructor(
        private notification: ToastrService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(SolarPvSurplusService)
        public solarPvSurplusService: SolarPvSurplusService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        this.showMobileUi = this.usefulService.showMobileUi;
    }

    collapseProductionPlot() {
        this.activeCollapseProduction = !this.activeCollapseProduction;
        if (this.activeCollapseProduction) {
            this.labelCollapseProduction =
                'Masquer les détails d’autoconsommation, achat et vente d’électricité';
        } else {
            this.labelCollapseProduction =
                'Afficher les détails d’autoconsommation, achat et vente d’électricité';
        }
    }

    async downloadExcelResults() {
        try {
            this.isDownloading = true;
            const buffer = await this.solarPvSurplusService.downloadPvSurplus();
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const date = new Date();
            const today = date.toLocaleDateString(navigator.language).replaceAll('/', '');
            const time = date
                .toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit',
                })
                .replaceAll(':', '');
            saveAs(blob, `Simulation-projet-solaire-autoconsommation-${today}${time}.xlsx`);
        } catch (error) {
            console.error('Error downloadExcelResults', error);
            this.notification.error(
                "Une erreur est survenue. Impossible d'exporter les données des graphiques.",
            );
        } finally {
            this.isDownloading = false;
        }
    }

    showTypicalDayPlot() {}

    showTypicalYearPlot() {}
}
