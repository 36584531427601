import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from 'src/environments/environment';
import { TerritoryScale } from 'src/app/models/TerritoryTypes';

import { AuthService } from 'src/app/services/AuthService';
import { ClearService } from 'src/app/services/ClearService';
import { EventService } from 'src/app/services/event.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ModuleService } from 'src/app/services/module.service';
import { TerService } from 'src/app/services/TerService';
import { ThemeService } from 'src/app/services/ThemeService';
import { UsefulService } from 'src/app/services/UsefulService';

import { TerritorySelectionModalComponent } from 'src/app/controllers/main/modals/territory-selection/territory-selection-modal.component';
import { ScenariosModalComponent } from 'src/app/controllers/main/modals/scenarios/scenarios-modal.component';
import { TutorialModalComponent } from '../modals/tutorial/tutorial-modal.component';

@UntilDestroy()
@Component({
    selector: 'stHeader',
    templateUrl: environment.header,
})
export class StHeader implements OnInit {
    @Input() hideDisconnect = false;
    @Input() isAdminPage = false;

    isInitialized = false;
    hasAdminButton = !!environment.admin;
    isAdmin = false;
    siterreLogo: string;
    logo: string;
    siteName: string;
    isActive: boolean = true;
    territoryScales: TerritoryScale[];
    territories: any;
    profile = '';
    isScenariosAvailable: boolean = false;

    public isCollapsed: boolean = false;
    public isTerritorySelectionModalOpen: boolean = false;
    public showMobileUi = false;

    constructor(
        private modalService: NgbModal,
        private router: Router,
        @Inject(AuthService) private authService: AuthService,
        @Inject(ClearService) private clearService: ClearService,
        @Inject(EventService) private eventService: EventService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(TerService) private terService: TerService,
        @Inject(ThemeService) private themeService: ThemeService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    ngOnInit(): void {
        this.profile = this.localStorageService.get('profile') || environment.name;
        const user = this.localStorageService.get('user');
        this.isAdmin = user && [1, 2].includes(user.role);
        this.logo = this.localStorageService.get('logo');
        this.siteName = this.localStorageService.get('name_site');
        this.showMobileUi = this.usefulService.showMobileUi;

        this.isCollapsed = !!this.showMobileUi;

        if (this.localStorageService.get('user') == undefined) {
            this.authService.logOut();
            this.router.navigate(['/']);
        }

        const preferences = this.localStorageService.get('preferences');
        if (preferences) {
            const modulesAccess = preferences.modules;
            this.isScenariosAvailable = modulesAccess.scenarios;
        }

        if (this.profile == 'siterre') {
            this.siterreLogo = this.themeService.theme.info.img.siterreLogo;
        }

        this.eventService.themeUpdated
            .pipe(untilDestroyed(this))
            .subscribe(() => (this.siterreLogo = this.themeService.theme.info.img.siterreLogo));

        this.eventService.mainInitialized.pipe(untilDestroyed(this)).subscribe(() => {
            if (!this.isAdminPage) {
                this.isInitialized = true;
                this._setSelectedTerritory();

                const preferences = this.localStorageService.get('preferences');
                const modulesAccess = preferences.modules;
                if (modulesAccess.territorySelection) {
                    this.openTerritorySelectionModal();
                }
            }
        });

        this.eventService.selectionTerritoryUpdated
            .pipe(untilDestroyed(this))
            .subscribe(() => this._setSelectedTerritory());

        this.eventService.territoryPloted
            .pipe(untilDestroyed(this))
            .subscribe(() => this._setSelectedTerritory());

        this.eventService.searchAddressSelected
            .pipe(untilDestroyed(this))
            .subscribe(() => this._setSelectedTerritory());

        this.eventService.indicatorPloted
            .pipe(untilDestroyed(this))
            .subscribe(() => this._setSelectedTerritory());

        this.eventService.indicatorUnploted
            .pipe(untilDestroyed(this))
            .subscribe(() => this._setSelectedTerritory());
    }

    private _setSelectedTerritory() {
        this.territories = this.terService.territories.map((t) => t.label);
    }

    isLogged() {
        return !!this.localStorageService.get('user');
    }

    goToAdmin() {
        this.modalService.dismissAll();
        if (this.localStorageService.get('user').role <= 2) {
            this.router.navigate(['administration/users/manage/']);
        }
    }

    goToMain() {
        this.modalService.dismissAll();
        this.router.navigate(['main']);
    }

    logOut() {
        this.modalService.dismissAll();
        this.authService.logOut();
        this.clearService.clearAfterLogOut();
        this.router.navigate(['/']);
    }

    openScenarioModal() {
        this.modalService.dismissAll();
        this.modalService.open(ScenariosModalComponent, { size: 'xl' });
    }

    openTutorialModal() {
        this.modalService.dismissAll();
        this.modalService.open(TutorialModalComponent, { size: 'xl' });
    }

    openTerritorySelectionModal() {
        this.modalService.dismissAll();
        this.isTerritorySelectionModalOpen = true;

        const modalRef = this.moduleService.territorySelectionModal;
        const isDefined = !!modalRef;
        const instance = isDefined ? modalRef.componentInstance : undefined;

        if (!isDefined || !instance) {
            const modalRef = this.modalService.open(TerritorySelectionModalComponent, {
                size: 'lg',
                backdropClass: 'territory-selection-custom-backdrop',
                modalDialogClass: 'territory-selection-custom-dialog',
            });

            modalRef.result.then(
                (result) => (this.isTerritorySelectionModalOpen = false),
                (dismiss) => (this.isTerritorySelectionModalOpen = false),
            );

            this.moduleService.setTerritorySelectionModal(modalRef);
        }
    }
}
